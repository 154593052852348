.homeTab{
    text-align: center;

}

.hello{
    font-size: 5rem;
    color: white;
    margin-bottom: 5px;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    width: 75%;
    

}
.homeContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 13%;
    font-size: 1.1em;
    
}
.developer{
    color: white;

    margin-top:0;
    font-size: 3rem;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    width: 70%;
    animation: DevAnim;
    animation-duration: 1s;
    transition-property: transform;
    position: relative;
    transition-duration:.8s;
}

.name {
    color: red;
    display: inline;
    animation: NameAnim;
    animation-duration: 1s;
    transition-property: transform;
    position: relative;

}
.helloIm{
    animation: HelloAnim;
    animation-duration: 1s;
    position: relative;
}

.projectBtn{
    position: absolute;
    bottom: 20%;
    width: 20%;
    right: 32.5%;
    border: 2px solid;
    border-color: red;
    color: white;
    font-weight: bold;
    background-color: transparent;
    border-radius: 20px;
    height: 5rem;
    justify-content: center;
    transition: opacity .25s;
    font-family: 'Merriweather', serif;
    font-size:2rem;
    animation: ButtonAnim;
    animation-duration: 1.2s;
    transition-property: transform;
}
.projectBtn:hover{
    opacity:1;
    transform:translateY(0);
    background-color: red;
    border-color: white;
    cursor:pointer;
    ;

    
}
@keyframes NameAnim {
    0%{
        top: -700px;
    }
    100%{
        top: 0;
    } 
}
@keyframes HelloAnim {
    0%{
        left: -900px;
    }
    100%{
        left: 0;
    } 
}
@keyframes DevAnim {
    0%{
        left: 900px;
        
    }
    
    100%{
        left: 0;
    } 
}
@keyframes ButtonAnim {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0;
        transform: rotateY(180deg)

    }
    100%{
        opacity: 1;
    } 
}
@media(max-height:600px){
    .homeContent{
        margin-top: 5%;
    }
    .projectBtn{
        bottom: 10%;
    }
}
@media(max-height:400px){
    .homeContent{
        margin-top: 0;
        font-size: .6em;
    }
    .projectBtn{
        font-size: .4em;
    }
}
@media(max-width:700px){
    .projectBtn{
        font-size: .6em;
        bottom: 10%;
        width: 40%;
        right: 20%;

    }
    .homeContent{
        font-size: .6em;
    }
}
@media(max-width:1100px){
    .projectBtn{
        font-size: 1.5em;
        bottom: 20%;
    }
    .homeContent{
        font-size: .9em;
    }
    .hello{
        font-size: 4em;
    }
}