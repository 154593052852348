.contactFormDiv{
    height: 75%;
    width: 60%;
    border: 2px solid red;
    border-radius: 20px;
    z-index: 1;
    padding: 25px;
}
.contactForm{
    height: 95%;
    display: grid;
    grid-template-rows: 15% 20% 40% 10%;
    margin-top: 4%;
    background-color: black;
    margin-bottom: 4%;
    
}
.contactItem{
    width: 60%;
    margin-left: 20px;
    border: 2px solid red;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    font-size: 1.5rem;
    z-index: 0;
    justify-content: center;

}
.contactName, .contactEmail{
    height: 2em;
}

.formItem{
    align-items: left;
}
.formItem label{
    align-items: left;

}
.contactMessage{
    height: 9em;
}
label{
    color: white;
    font-size: 2rem;
    margin-bottom: 10px;
    align-items: left;
    float: left;
}
.labelMessage{
    margin-right: -4%;
}
.formBtn{
    border: 2px solid;
    border-color: red;
    color: white;
    font-weight: bold;
    background-color: transparent;
    border-radius: 20px;
    height: 5rem;
    justify-content: center;
    font-family: 'Merriweather', serif;
    font-size:1.5rem;
    padding: 25px;
    width: 40%;
    align-items: center;
    margin-left: 35%;
}
.formBtn:hover{
    opacity:1;
    background-color: red;
    border-color: white;
    cursor:pointer;
}
.warning{
    color: red;
    font-size: 1.4em;
    display: none;
}
.warningShow{
    display: block;
    background-color:white;
    width: 80%;
    text-align: center;
    margin-left: 10%;
    color: red;
    font-size: 1.4em;
}
@media(max-height:700px){
    .contactMessage{
        height: 5em;
    }
}
.sentAlreadyBtn{
    border: 2px solid;
    border-color: red;
    color: white;
    font-weight: bold;
    background-color: transparent;
    border-radius: 20px;
    height: 5rem;
    justify-content: center;
    font-family: 'Merriweather', serif;
    font-size:1.5rem;
    padding: 25px;
    width: 40%;
    align-items: center;
}
.sentAlready{
    width: 85%;
    margin-left: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media(max-width:700px){
    label{
        font-size: 1em;
    }
    .formBtn{
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .contactForm{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}