.project{
    background-color: black;
    color:black ;
    width: 100%;
    height: 100%;
    display:flex;
    position: relative;
    justify-content: center;
    align-items:center;
    border: 3px solid;
    border-color: black;
    animation: projectAnims;
    animation-duration: .5s;
}
.projectName{
    position: relative;
    font-size: 1.5rem;
    animation-name: leftName;
    animation-duration: .5s;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
@keyframes leftName {
    0%{
        left: -600px;
        opacity:0;
    }
    100%{
        left:0;
        opacity:1;
    } 
}

.chess{
    background-image:url("./assets/chess.PNG");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.stonks{
    background-image: url("./assets/stonks.PNG");
    background-repeat: no-repeat;
    background-size: cover;

    background-position: center;
}
.encrypt{
    background-image: url("./assets/encrypter.PNG");
    background-repeat: no-repeat;
    background-size: cover;

    background-position: center;
}
.jobTrack{
    background-image: url("./assets/app_track.PNG");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}
.books{
    background-image: url("./assets/books2.PNG");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}

.techImage{
    height: 40px;
    padding: 5px;
}

.moreDetailBtn{
    background-color: white; 
    color: black; 
    border: 2px solid #4CAF50;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
}
.moreDetailBtn:hover{
    background-color: blue;
    color:white;
}
.overLay{
    z-index: 1;
    position:absolute;
    font-family: 'Slabo 27px', serif;
    background-color: #25008a;
    background-image: url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png");
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
    color: white;
    top:0;
    left:0;
    opacity:0;
    width:80%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    padding-left: 10%;
    padding-right: 10%;
    transition: opacity .25s;
}
.overLay:hover{
    opacity:.96;
}
.overLayTitle{
    display:flex;
    justify-content: center;
    align-items:center;
    height: 20%;
    opacity:1;
}
.overLayBody{
    height: 60%;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items:center;
    margin-bottom: 20px;
    font-size: 1rem;

}
.overLayLinks{
    height: 20%;
    display:grid;
    grid-template-columns: 33% 33% 33%;
    width: 80%;
    flex-direction: row;
    text-decoration: underline;
    font-size:1;


}
.overLayLink:hover{
    color:yellow;
    font-size: 2rem;
}
.overLayLink{
    padding: 15px;
}
.overLay:hover > *{
    opacity:1;
    transform:translateY(0);
}

.overLay > *{
    transform: translateY(90px);
    transition: transform .25s;

}
.resButtonProjects{
    margin-top: 50px;
    margin-bottom: 50px;
}
  

@keyframes projectAnims {
    0%{top: 600px;}
    100%{top:0;}  
}

@media(max-width:1350px){
    .overLayBody{
        font-size: 1.5rem;
    }
}
@media(max-width:1050px){
    .overLay{
        font-size: .7em;
    }
    .overLayBody{
        font-size: 1em;
    }
    .overLayTitle{
        display: none;
    }
    .overLayLinks{
        font-size: 1em;
    }
    .overLayLink:hover{
        font-size: 1.4em;
    }
    .techImage{
        height: 40px;
    }
}
/* @media(max-width:1000px){
    .overlayTitle{
        display:none;
    }
    .overLayBody{
        font-size: 1rem;
    }
    overLayLinks{
        font-size:.5rem;
    }
} */