.resume{
    display:none;
}
.resumeTab{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;

}
.resEmbTab{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}
.resBtn{
    border: 2px solid;
    border-color: red;
    color: white;
    font-weight: bold;
    background-color: transparent;
    border-radius: 20px;
    height: 5rem;
    justify-content: center;
    transition: opacity .25s;
    font-family: 'Merriweather', serif;
    font-size:2rem;
    padding: 25px;
    margin: 10px;
    position: relative;
}
.resBtn:hover{
    opacity:1;
    transform:translateY(0);
    background-color: red;
    border-color: white;
    cursor:pointer;
}
.resOne{
    animation: viewRes;
    animation-duration: 1s;
}
.resTwo{
    animation: downRes;
    animation-duration: 1s;
}


@keyframes viewRes {
    0%{
        left: -900px;
    }
    70%{
        left: 10px;
    }
    100%{
        left: 0;
    } 
}
@keyframes downRes {
    0%{
        left: 900px;
        
    }
    70%{
        left: -10px;
    }
    100%{
        left: 0;
    } 
}
@media(max-width:768px){
    .resBtn{
        font-size:1em;
        padding: 10px;
        height: 5em;
    }
}