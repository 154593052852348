.aboutProject{
    width: 85%;
}
.modal{
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    margin:0;
    top: 0;
    left:0;
    opacity: .99;
    justify-content:center;
    align-items:center;
    overflow:hidden;
    font-size:1.2em;
    font-family: 'Merriweather', serif;
}
.modalHeader{
    color: green;
}
.modalHeaderFile{
    color: #2d1152;
}
.process{
    width: 85%;
    
}
.processEssay{
    text-align: left;
    text-indent: 40px;
}
.inner-modal{
    z-index: 1;
    width: 60%;
    background: antiquewhite;
    background-image: url("https://www.transparenttextures.com/patterns/absurdity.png");
    height: 98%;
    margin-top:1%;
    overflow-y:auto;
    overflow-x:hidden;
    padding-bottom: 50px;
    margin-left: 20%;
    opacity:1;
}
.modalTechImage{
    height: 60px;

}
.modalBody{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items:center;
    overflow: auto;
}
.modalBtns{
    display: flex;
    flex-direction: row;
    margin-top: 25px;
}
.modalBtn{
    background-image: url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png");
    color: white;
    z-index: 0;
    opacity:1;
    width: 100px;
    height: 50px;
    margin: 1rem;
}
.chessBtn, .bookBtn{
    background-color: green;
}
.trackBtn{
    background-color: blue;
}
.encBtn{
    background-color: #2d1152;
}
.modalBtn:hover{
    color: yellow;
    background-color: #25008a;
    cursor: pointer;
}   
.stockHeader{
    color: red;
}
.stockBtn{
    background-color: red;
    margin-left: 30%;
}
.techUsedModal{
    margin-bottom: 75px;
}
.jobHeader{
    color: blue;
}
.bookHeader{
    color: green;
}
.linkModal a{
    color: blue;
    text-decoration: underline;
    font-style: italic;
}
::-webkit-scrollbar {
    width: 10px;
    }
    
::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px rgb(240, 240, 240); 
border-radius: 10px;
}
::-webkit-scrollbar-thumb {
background:rgb(102, 97, 97);
border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
background:red; 
}
@media(max-width:600px){
    .inner-modal{
        width: 90%;
        margin-left: 5%;
    }
}