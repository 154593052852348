:root{
    --projHeight: 35vw;
    --ratio: calc(10/16);
    --proj_width: calc(var(--projHeight) * var(--ratio));
    --proj_other_width: calc(var(--projHeight) * .5);
    --projHeightSingle: 70vw;
    --proj_width_single: calc(var(--projHeightSingle) * var(--ratio));
    --proj_other_width_single: calc(var(--projHeightSingle) * .5);


}
.projects{
    display:grid;
    grid-template-columns: var(--projHeight) var(--projHeight);
    grid-template-rows: var(--proj_width) var(--proj_width) var(--proj_other_width) var(--proj_other_width) var(--proj_other_width) ;
    grid-row-gap: 80px;
    grid-column-gap: 3vw;
    margin: auto;
    width: 100%;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 100px;
}

h1{
    color: red;
}
.resButtonProjects{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media(max-height: 1000px){
    overLayBody{
        display: none;
    }
}


@media(max-width: 1350px){
    .projects{
        grid-template-columns: var(--projHeightSingle);
        grid-template-rows: var(--proj_width_single) var(--proj_width_single) var(--proj_other_width_single) var(--proj_other_width_single) var(--proj_other_width_single) ;
        grid-row-gap: 80px;
        grid-column-gap: 3vw;
        margin: auto;
        width: 100%;
        justify-content: center;
        margin-top: 25px;
        margin-bottom: 100px;
    }
    .overlayTitle{
        font-size: 1em;
    }
    .overlayLinks{
        font-size: 1em;
    }
    .overlayLinks:hover{
        font-size: 1.5em;
    }
}

@media(max-width: 900px){
    .overlayTitle{
        font-size: 1em;
    }
}

@media(max-width: 700px){
    .overlayTitle{
        display: none;
    }
    .overLayBody{
        display: none;
    }
    .overlayLinks{
        font-size: 1em;
    }
    .overlayLinks:hover{
        font-size: 1.5em;
    }
}

