.left-side{
    width: 15%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: fixed;
    overflow: hidden;
    border-right: 2px solid;
    border-color: teal;
    font-family: 'Merriweather', serif;
    background-image: url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png");
    background-color: #25008a;
    z-index: 1;

}
.navLinkDiv{
    height: 150px;
    font-size: 3rem;

}
.navLinkOne{
    margin-top: 50px;
}
.navLink:hover{
    text-decoration:none;
}
.navLink.active{
    color: yellow;
    font-weight: bold;
}
a{
    text-decoration: none;
    color: white;
}
@media(max-width:1300px){
    .left-side{
        width: 20%;
    }
    .navLinkDiv{
        font-size: 1.5em;
    }
}
@media(max-width:700px){
    .navLinkDiv{
        font-size: 1em;
    }
}
@media(max-height:600px){
    .navLinkOne{
        margin-top: 50px;

    }
    .navLinkDiv{
        font-size: 2em;
    }
}

